import './App.css';
import HomePage from "./pages/Home";
import {AppBar, CssBaseline, ThemeProvider, Toolbar, Typography} from "@mui/material";
import theme from "./theme";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import {Login} from "./pages/Login";
import React from "react";

function App() {
    return (
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="App">
                    <Routes>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="/" element={<HomePage/>}/>
                        </Route>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
