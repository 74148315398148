import {createTheme} from "@mui/material";
import {itIT} from "@mui/material/locale";
import {itIT as datagridItIT} from "@mui/x-data-grid";

const theme = createTheme({

    palette: {
        mode: 'light',
        primary: {
            main: '#263238',
        },
        secondary: {
            main: '#e6fc69',
        },
        accent: {
            main: '#e6fc69',
        },
    },

    itIT
}, datagridItIT)

export default theme;
