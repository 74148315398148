import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {Alert, AppBar, Box, Button, Container, Snackbar, Stack, Toolbar, Typography} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import CreateUpdateWebsite from "../components/CreateUpdateWebsite";

const useSaveRow = () => {
    return useCallback(
        (website) => {
            const requestOptions = {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(website)
            };

            return fetch(process.env.REACT_APP_API_URL + 'websites', requestOptions)
                .then((res) => res.json())
        },
        [],
    );
};

const HomePage = () => {

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'data',
            headerName: 'Data Evento',
            width: 140,
            type: 'date',
            editable: true,
            valueGetter: (params) => {
                return (params.value?._seconds ?
                    new Date((params.value?._seconds * 1000))
                    : params.value ?
                        new Date(params.value)
                        : "");
            },
            valueFormatter: (params) => {
                return (params.value?._seconds ?
                    new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                    : params.value ?
                        new Date(params.value).toLocaleDateString('it-IT')
                        : "");
            },
        },
        {
            field: 'nome',
            headerName: 'Nome Evento',
            flex: 0.75,
            editable: false,
        },
        {
            field: 'link',
            headerName: 'Link',
            flex: 1,
            editable: false,
            renderCell: (params) => params.value ?
                <a target={"_blank"} href={params.value} rel="noreferrer">{params.value}</a> : ''
        },
        {
            field: 'note',
            headerName: 'Note',
            flex: 1,
            editable: true,
        },
    ];

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeWebsite, setActiveWebsite] = useState(null)
    const [pageSize, setPageSize] = useState(50)

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({children: error.message, severity: 'error'});
    }, []);

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "websites")
            .then(res => res.json())
            .then(
                (result) => {
                    setList(result);
                    setLoading(false);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        loading: false,
                        error
                    });
                }
            )
    }, [])

    const [snackbar, setSnackbar] = useState(null);
    const saveRow = useSaveRow();

    const createWebsite = () => {
        setActiveWebsite(null);
        setEditDialogOpen(true);
    }

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        async (newRow) => {
            // Make the HTTP request to save in the backend
            const response = await saveRow(newRow);
            setSnackbar({children: 'Website successfully saved', severity: 'success'});
            return response;
        },
        [saveRow],
    );

    // TODO: CHIP
    // TODO: LOGIN
    // TODO: CREA EVENTO

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Plesh Websites
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth={"xl"}>
                <Stack direction={"row"} justifyContent={"flex-end"} sx={{my: 3}}>
                    <Button onClick={() => createWebsite()} variant={"outlined"}>Crea Nuovo</Button>
                </Stack>
                <Box sx={{height: '75vh', width: '100%'}}>
                    <DataGrid
                        density={"compact"}
                        rows={list}
                        columns={columns}
                        processRowUpdate={processRowUpdate}
                        rowsPerPageOptions={[15, 25, 50]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        experimentalFeatures={{newEditingApi: true}}
                        components={{Toolbar: GridToolbar}}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        loading={loading}
                    />
                </Box>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                    </Snackbar>
                )}
                <CreateUpdateWebsite open={editDialogOpen} handleClose={handleEditDialogClose}/>
            </Container>
        </Box>
    )
}

export default HomePage;
