import React, {useEffect, useState} from "react";
import {Box, Button, Card, Container, Stack, TextField, Typography, useTheme} from "@mui/material";
import {firebase, auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const theme = useTheme();

    const logIn = async (e) => {
        e.preventDefault()
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
            });

    }

    return(
        <Box sx={{textAlign:'center', width:'100%', height: '100%', minHeight: '100vh', pt:10, background: theme.palette.primary.main}}>
            <Container maxWidth={'sm'}>
                <Typography variant={"h4"} fontWeight={'bold'} sx={{my: 5}} color={'white'}>
                    Accedi
                </Typography>
                <Card variant={'gradient'}>
                    <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={logIn}>
                        <Stack direction={"column"} spacing={4}>
                            <TextField variant={'standard'} onChange={(e) => setEmail(e.target.value)} label={"Email"}/>
                            <TextField type='password' variant={'standard'} onChange={(e) => setPassword(e.target.value)} label={"Password"}/>
                            <Box sx={{textAlign: 'center'}}>
                                <Button sx={{my: 2}} variant={"contained"} type={"submit"}>
                                    Invia
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                </Card>
            </Container>
        </Box>
    )
}
