import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {useState} from "react";

const CreateUpdateWebsite = ({open, handleClose}) => {

    const [date, setDate] = useState();
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleDateChange = (e) => {
        setDate(new Date(e.$d));
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    }

    const createWebsite = async () => {
        setDisabled(true)
        const website = {
            data: date,
            link: url,
            nome: name,
            note: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(website)
        };

        await fetch(process.env.REACT_APP_API_URL + 'websites', requestOptions)
            .then((res) => res.json())
        setDisabled(false)
        handleClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Crea Website</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nome Evento"
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                    variant="standard"
                />
                <TextField
                    sx={{mb: 4}}
                    margin="dense"
                    id="link"
                    label="Link Completo"
                    type="text"
                    value={url}
                    onChange={handleUrlChange}
                    fullWidth
                    variant="standard"
                />
                <DesktopDatePicker
                    label="Data Evento"
                    inputFormat="DD/MM/YYYY"
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled} onClick={handleClose}>Annulla</Button>
                <Button disabled={disabled} onClick={createWebsite}>Conferma</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateUpdateWebsite;
